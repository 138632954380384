@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #f4f4f4;
  background-image: linear-gradient(to right, #f4f4f4, #e8e8e8);
  color: #333;
  line-height: 1.6;
  font-size: 16px;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  padding: 20px;
}

header {
  background: linear-gradient(to right, #333, #555);
  color: #fff;
  padding-top: 30px;
  min-height: 70px;
  border-bottom: #bbb 1px solid;
}

header a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  transition: color 0.3s;
}

header a:hover {
  color: #ddd;
}

nav {
  float: right;
  padding: 15px;
}

nav ul {
  list-style: none;
}

nav ul li {
  display: inline;
  margin-left: 20px;
}

.main {
  flex: 1;
  padding: 20px;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 30px 0;
  /* margin-top: 40px; */
}

button, .btn {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background: #333;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover, .btn:hover {
  background: #555;
}

form {
  max-width: 500px;
  margin: 20px auto;
}

form label {
  display: block;
  margin-bottom: 5px;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="date"],
form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: border-color 0.3s;
}

form input[type="submit"] {
  width: 100%;
  display: block;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.text-center {
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #333;
  color: #fff;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e9e9e9;
}

.center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: auto;
  max-width: 600px;
  margin: 10% auto;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

@media screen and (max-width: 768px) {
  .modal-content {
    margin: 10% 10 px;
    width: calc(100% - 20px);
  }
}

/* The Close Button */
.close-button {
  color: #aaa;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Draggable Stuff */

.draggable {
  cursor: move;
  border: 1px dashed #bbb;
  padding: 5px;
  background-color: #f4f4f4;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  align-items: center;
  min-height: 40px;
}

.dragging {
  opacity: 0.5;
  background-color: #e8e8e8;
}

.edit-mode .draggable {
  background-color: #e8e8e8;
  border-color: #bbb;
}

.edit-mode .draggable:hover {
  background-color: #ddd;
}

.empty-agent {
  color: #ccc;
}

.unpaired-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.unpaired-item {
  margin: 5px 0;
}


@media screen and (max-width: 768px) {
  .container, header, .main, footer {
    width: 100%;
    padding: 0;
  }

  nav ul li {
    display: block;
    margin: 0;
  }

  nav {
    padding: 0;
  }
}

