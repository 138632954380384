.calendar-container {
    text-align: center;
    margin: 20px 0;
    max-width: 600px; /* Adjust the width as needed */
    margin: auto;
}

.calendar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.calendar-month {
    font-size: 20px;
    font-weight: bold;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* 7 days a week */
    gap: 5px;
    padding: 10px;
}

.calendar-header.selected {
    background-color: #333; /* Match with .calendar-day.selected */
    color: white; /* Ensures text is visible on the dark background */
    border: 1px solid #d0d0d0; /* Consistent with non-selected headers */

    &:hover {
        background-color: #555;
    }
}

.calendar-header {
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #d0d0d0;
    text-align: center;
    padding: 5px;
    margin: 2px;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0e0e0;
    }
}

.calendar-day.no-transition {
    transition: none;
}

.calendar-day {
    background-color: #e8e8e8;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.calendar-day:hover {
    background-color: #ddd;
}

.calendar-day.selected {
    background-color: #333;
    color: white;

    &:hover {
        background-color: #555;
    }
}

.calendar-day.empty {
    background: none;
    cursor: default;
}
